<template>
  <div class="sales-chart chart">
    <pie-chart :data="chartData" :library="library" :colors="chartColors"></pie-chart>
    <Spacer num="2" />
  </div>
</template>

<script>
export default {
  props: ["statistics"],
  data: function() {
    return {
      library: {
        is3D: true,
        pieHole: 0.4,
        pieSliceText: "value",
        pieSliceTextStyle: {
          color: "white",
          fontName: "Ubuntu Bold",
          fontSize: 14,
        },
        legend: {
          position: "bottom",
          textStyle: {
            fontSize: 13,
            fontName: "Ubuntu Bold",
          },
        },
      },
    };
  },
  computed: {
    chartData: function() {
      return {
        [this.$locale.words["content"]]: this.statistics.totalContent,
        [this.$locale.words["gifts"]]: this.statistics.totalGifts,
        [this.$locale.words["flowers"]]: this.statistics.totalFlowers,
        [this.$locale.words["tips"]]: this.statistics.totalTips,
      };
    },
    chartColors: function() {
      return [this.$locale.colors["content"], this.$locale.colors["gifts"], this.$locale.colors["flowers"], this.$locale.colors["tips"]];
    },
  },
};
</script>

<style lang="scss">
.sales-chart {
  position: relative;
  background-color: #fff;
  @include shadow;
  border-radius: $mpadding/2;
  svg {
    > rect {
      fill: rgba(255, 255, 255, 0);
    }
  }
}
*.__loading {
  .sales-chart {
    svg g path {
      fill: #f3f3f3;
      stroke: #f3f3f3;
      animation: ItermitFill 6s ease infinite alternate;
    }
    svg g text[text-anchor] {
      fill: #f3f3f300;
      background-color: #f3f3f3;
    }
  }
}
@include ItermitFill(#f3f3f3, #fcfcfc);
</style>
